// src/CryptoPrices.js
import React, { useState, useEffect } from "react";
import "../styles.css";

const SUPPORTED_COINS = {
  bitcoin: "https://api.coingecko.com/api/v3/coins/bitcoin",
  ethereum: "https://api.coingecko.com/api/v3/coins/ethereum",
  cardano: "https://api.coingecko.com/api/v3/coins/cardano",
  sonic: "https://api.coingecko.com/api/v3/coins/fantom",
};

function CryptoPrices() {
  const [bitcoinData, setBitcoinData] = useState(null);
  const [ethereumData, setEthereumData] = useState(null);
  const [cardanoData, setCardanoData] = useState(null);
  const [sonicData, setSonicData] = useState(null);
  const [expandedBitcoin, setExpandedBitcoin] = useState(false);
  const [expandedEthereum, setExpandedEthereum] = useState(false);
  const [expandedCardano, setExpandedCardano] = useState(false);
  const [expandedSonic, setExpandedSonic] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [error, setError] = useState(null); // Add an error state

  useEffect(() => {
    const fetchCoinData = async (coin) => {
      try {
        const response = await fetch(SUPPORTED_COINS[coin]);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        if (coin === "bitcoin") {
          setBitcoinData(data);
        } else if (coin === "ethereum") {
          setEthereumData(data);
        } else if (coin === "cardano") {
          setCardanoData(data);
        } else if (coin === "sonic") {
          setSonicData(data);
        }
        setError(null); // Clear the error state on successful fetch

        // console.log(`Successfully fetched data for ${coin}`); // Log success
      } catch (error) {
        console.error("Error fetching", coin, "data:", error);
        setError(error); // Set the error state
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchCoinsWithDelay = async () => {
      const coinPromises = ["bitcoin", "ethereum", "cardano", "sonic"].map(
        async (coin, index) => {
          await new Promise((resolve) => setTimeout(resolve, index * 1000)); // Stagger by 1 second
          return fetchCoinData(coin);
        }
      );

      await Promise.all(coinPromises);
    };

    fetchCoinsWithDelay();

    // Set up an interval to fetch data every 30 seconds
    const intervalId = setInterval(() => {
      fetchCoinData("bitcoin");
      fetchCoinData("ethereum");
      fetchCoinData("cardano");
      fetchCoinData("sonic");
    }, 120000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const toggleDetails = (coin) => {
    if (coin === "bitcoin") {
      setExpandedBitcoin(!expandedBitcoin);
    } else if (coin === "ethereum") {
      setExpandedEthereum(!expandedEthereum);
    } else if (coin === "cardano") {
      setExpandedCardano(!expandedCardano);
    } else if (coin === "sonic") {
      setExpandedSonic(!expandedSonic);
    }
  };

  return (
    <div>
      <h1>Cryptocurrency Price Information</h1>
      {isLoading ? (
        <p>Loading data...</p>
      ) : error ? (
        <p>Error fetching data: {error.message}</p>
      ) : (
        <ul>
          <li onClick={() => toggleDetails("bitcoin")}>
            {bitcoinData?.name}: $
            {bitcoinData?.market_data?.current_price?.usd?.toLocaleString()}
            <span className="arrow">{expandedBitcoin ? "▼" : "◄"}</span>
          </li>
          {expandedBitcoin && (
            <li className="expanded-details">
              <p>
                Market Cap: $
                {bitcoinData?.market_data?.market_cap?.usd?.toLocaleString()}
              </p>
              Number of Sats per 1 USD{" "}
              {(
                100000000 / bitcoinData?.market_data?.current_price?.usd
              )?.toFixed(0)}
            </li>
          )}
          <li onClick={() => toggleDetails("ethereum")}>
            {ethereumData?.name}: $
            {ethereumData?.market_data?.current_price?.usd?.toLocaleString()}
            <span className="arrow">{expandedEthereum ? "▼" : "◄"}</span>
          </li>
          {expandedEthereum && (
            <li className="expanded-details">
              <p>
                Market Cap: $
                {ethereumData?.market_data?.market_cap?.usd?.toLocaleString()}
              </p>
              {/* ... other details for Ethereum */}
            </li>
          )}
          <li onClick={() => toggleDetails("cardano")}>
            {cardanoData?.name}: $
            {cardanoData?.market_data?.current_price?.usd?.toLocaleString()}
            <span className="arrow">{expandedCardano ? "▼" : "◄"}</span>
          </li>
          {expandedCardano && (
            <li className="expanded-details">
              <p>
                Market Cap: $
                {cardanoData?.market_data?.market_cap?.usd?.toLocaleString()}
              </p>
              {/* ... other details for Cardano */}
            </li>
          )}
          <li onClick={() => toggleDetails("sonic")}>
            {sonicData?.name}: $
            {sonicData?.market_data?.current_price?.usd?.toLocaleString()}
            <span className="arrow">{expandedSonic ? "▼" : "◄"}</span>
          </li>
          {expandedSonic && (
            <li className="expanded-details">
              <p>
                Market Cap: $
                {sonicData?.market_data?.market_cap?.usd?.toLocaleString()}
              </p>
              {/* ... other details for Sonic */}
            </li>
          )}
        </ul>
      )}
    </div>
  );
}

export default CryptoPrices;
